/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic); */

/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */

a {
    text-decoration: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    font-family: "Open Sans", sans-serif;
    color: #141518;
    background-color: white;
}

*::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: #1bb76e; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 2px solid white; /* creates padding around scroll thumb */
}

.video_icon {
    position: absolute;
    left: 5px;
    top: 5px;
    color: #11e481;
    text-shadow: 1px 1px 2px black;
}

.video_icon svg {
    filter: drop-shadow(0px 5px 2px rgb(0 0 0 / 0.4));
}
