.card {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    background: rgb(245, 243, 243);
    margin-bottom: 32px;
    position: relative;
    transition: 300ms;
}

.card__image {
    width: 100%;
    height: auto;
}

.card__image img {
    width: 100%;
    height: 100%;
}

.card__fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
}

.card__input p {
    font-size: 14px;
    color: rgb(80, 80, 80);
}

.card__input input {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
}

.card__input_important::after {
    content: "*Обязательное поле";
    font-size: 12px;
    color: rgb(250, 0, 0, 0.8);
}

.card__input input:focus {
    outline: none;
}

.card__delete {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 100%;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 425px) {
    .card__fields {
        gap: 16px;
        padding: 16px;
    }
}
