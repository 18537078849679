.search__input {
    position: relative;
}

.search__input input {
    width: 100%;
    font-size: 20px;
    border-radius: 0;
    border: none;
    box-shadow: 0px 1px 5px gray;
    padding: 12px 64px 12px 24px;
    transition: outline 300ms;
    outline: 2px solid transparent;
}

.search__input input:focus {
    outline: 2px solid #1bb76e;
}

.video_checkbox {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 8px;
}

.search__icon {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: gray;
}

.search__icon_animate {
    animation-duration: 1s;
    animation-name: flicker;
    animation-iteration-count: infinite;
}

@keyframes flicker {
    0% {
        color: rgb(212, 212, 212);
    }
    50% {
        color: black;
    }
    100% {
        color: rgb(212, 212, 212);
    }
}
