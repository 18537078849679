.burgerMenu {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 0vh;
    background: #141518;
    z-index: 9;
    overflow: hidden;
    transition: 200ms;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.burgerMenu_open {
    height: 100vh;
    padding: 64px 16px;
}

.burgerMenu__user_name {
    position: relative;
    letter-spacing: 1px;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding-bottom: 4px;
}

.burgerMenu__user_name::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        white 51.04%,
        rgba(255, 255, 255, 0) 100%
    );
}

@media (max-width: 768px) {
    .burgerMenu {
        display: flex;
    }
}
