.card {
    cursor: pointer;
    background: rgb(245, 243, 243);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    transition: 300ms;
    outline: 2px solid transparent;
    overflow: hidden;
}

.card:hover {
    transform: scale(1.02);
    outline: 2px solid #1bb76e;
}

.card__image {
    width: 100%;
    height: 300px;
    position: relative;
}

.card__skeleton {
    position: absolute !important;
    border-radius: 0 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card__text {
    padding: 12px;
}

.card__text p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.card__name {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.card__author {
    font-size: 16px;
    letter-spacing: 0.5px;
}

.card__description {
    font-size: 14px;
    color: rgb(95, 95, 95);
    letter-spacing: 0.5px;
}
