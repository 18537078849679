.main__container {
    width: 100%;
    padding: 65px 0;
}

.main__container_first {
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.main__container_first input {
    border-radius: 30px;
}

.main__info {
    text-align: center;
    padding: 12px 12px 0 12px;
    color: gray;
}

.main__info svg {
    margin-bottom: -5px;
}

.main__body {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 24px 16px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
}

@media (max-width: 1440px) {
    .main__body {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1024px) {
    .main__body {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .main__container {
        padding: 52px 0;
    }
    .main__body {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 525px) {
    .main__body {
        grid-template-columns: 1fr;
    }
}
