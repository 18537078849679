.header {
    width: 100%;
    background: #141518;
    padding: 12px 48px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
}

.header__body {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 12px;
    align-items: center;
}

.header__logo {
    cursor: pointer;
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-right: 8px;
    transition: 200ms;
}

.header__logo:hover {
    text-shadow: #ccc 1px 0 10px;
}

.header__user_name {
    letter-spacing: 1px;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.header__burger {
    display: none;
}

@media (max-width: 768px) {
    .header {
        padding: 8px 12px;
    }
    .header__body {
        display: none;
    }
    .header__user_name {
        font-size: 12px;
    }
    .header__burger {
        display: block;
        margin: auto 0 auto auto;
    }
}
