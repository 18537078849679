.upload__container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 170px 16px;
    display: grid;
    grid-template-columns: 50% 48%;
    grid-column-gap: 2%;
}

.upload__cards {
    width: 100%;
}

.upload__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.upload__gen_description {
    width: 100%;
    padding: 16px;
    border-radius: 6px;
    background: rgb(245, 243, 243);
    height: min-content;
}

.upload__gen_description p {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px;
}

.upload__gen_description textarea {
    width: 100%;
    height: 300px;
    border-radius: 6px;
    border: none;
    outline: 2px solid transparent;
    transition: 300ms;
}

.upload__gen_description textarea:focus {
    outline: 2px solid #1bb76e;
    color: black;
}

@media (max-width: 768px) {
    .upload__container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        padding: 100px 16px;
        gap: 16px;
    }
    .upload__buttons {
        flex-direction: column;
        gap: 12px;
    }
    .upload__buttons button {
        width: 100%;
        font-size: 14px !important;
    }

    .upload__gen_description {
        padding: 8px;
    }

    .upload__gen_description p {
        font-size: 18px;
        margin-bottom: 6px;
    }
    .upload__gen_description textarea {
        font-size: 14px;
    }
}
