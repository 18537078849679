.login__container {
    width: 100%;
    max-width: 382px;
    margin: 0 auto;
    padding: 80px 16px 0 16px;
}

.login__title {
    font-size: 48px;
    text-align: center;
    letter-spacing: 4px;
}

.login__form {
    margin-top: 90px;
}

.form__title {
    position: relative;
    text-align: center;
    font-size: 12px;
    padding-bottom: 24px;
}

.form__title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        gray 51.04%,
        rgba(255, 255, 255, 0) 100%
    );
}

.form__body {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background: rgb(245, 243, 243);
}

.form__input {
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.9em;
    border: none;
    transition: 300ms;
    outline: 1px solid transparent;
}

.form__input:focus {
    outline: 1px solid #1bb76e;
    color: black;
}

.form__input:not(:last-child) {
    margin-bottom: 10px;
}

.form__error {
    text-align: center;
    font-size: 12px;
    padding-top: 15px;
    color: red;
}

@media (max-width: 425px) {
    .login__title {
        font-size: 36px;
    }
}
