.button {
    cursor: pointer;
    border: none;
    padding: 10px 25px;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    background: #5c69ff;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.button:hover {
    background: #8590ff;
}

.button_primary {
    background: #1bb76e;
    box-shadow: 0 6px 10px 0 rgb(27 28 30 / 31%);
}

.button_primary:hover {
    background: #28d685;
}

.button:disabled {
    cursor: not-allowed;
    background: #cccc;
}
