.modal {
    position: fixed;
    top: 64px;
    overflow-y: auto;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    animation: 0.2s show;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 16px;
}

.modal__body {
    margin: auto 0;
    margin-bottom: 64px;
    width: 100%;
    max-width: 712px;
    background: white;
    border-radius: 6px;
    animation: 0.2s showBody;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal__image {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    width: 100%;
    height: auto;
    position: relative;
    min-height: 50vh;
}

.modal__skeleton {
    position: absolute !important;
    border-radius: 0 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal__text {
    padding: 16px;
}

.modal__text p {
    margin-bottom: 4px;
    overflow-wrap: break-word;
}

.modal__name {
    font-size: 24px;
    font-weight: bold;
}

.modal__author {
    font-size: 18px;
}

.modal__user {
    font-size: 18px;
}

.modal__description {
    color: rgb(95, 95, 95);
}

.modal__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 100%;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

@keyframes showBody {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes showBg {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .modal {
        top: 52px;
    }
}
