.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    animation: 0.2s show;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 16px;
}

.modal__body {
    width: 100%;
    max-width: 912px;
    background: white;
    height: 306px;
    border-radius: 6px;
    animation: 0.2s showBody;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.modal__drop {
    width: 50%;
    height: 100%;
    border-radius: 6px 0 0 6px;
    background: radial-gradient(
            755px at 77.71% -37.81%,
            #441b6f 0,
            rgba(23, 23, 66, 0) 100%
        ),
        orange;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.drop__area {
    width: calc(100% - 64px);
    height: calc(100% - 64px);
    border: 3px dashed hsla(0, 0%, 100%, 0.4);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    z-index: 8;
}

.modal__drop_active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 6;
    animation: 0.5s showBg;
}

.modal__drop_active_opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 7;
    animation: 0.5s showBg;
}

.modal__select {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal__select input[type="file"] {
    display: none;
}

.select__file {
    font-size: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 300ms;
}

.select__file:hover {
    opacity: 0.7;
}

.select__text {
    font-size: 14px;
    font-weight: 200;
    margin-top: 4px;
    color: gray;
}

.modal__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 100%;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal__error {
    font-size: 14px;
    font-weight: 200;
    margin-top: 4px;
    color: red;
}

@media (max-width: 768px) {
    .modal__drop {
        display: none;
    }
    .modal__select {
        width: 100%;
    }
}

@media (max-width: 425px) {
    .select__file {
        font-size: 18px;
    }
    .select__text {
        font-size: 12px;
    }
}

@keyframes show {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes showBody {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes showBg {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
